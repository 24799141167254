// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-1-getting-started-mdx": () => import("./../../../../src/1. Getting Started.mdx" /* webpackChunkName: "component---src-1-getting-started-mdx" */),
  "component---src-2-data-import-mdx": () => import("./../../../../src/2. Data import.mdx" /* webpackChunkName: "component---src-2-data-import-mdx" */),
  "component---src-3-filtering-mdx": () => import("./../../../../src/3. Filtering.mdx" /* webpackChunkName: "component---src-3-filtering-mdx" */),
  "component---src-4-layout-settings-mdx": () => import("./../../../../src/4. Layout Settings.mdx" /* webpackChunkName: "component---src-4-layout-settings-mdx" */),
  "component---src-5-advanced-settings-mdx": () => import("./../../../../src/5. Advanced Settings.mdx" /* webpackChunkName: "component---src-5-advanced-settings-mdx" */),
  "component---src-6-data-structure-mdx": () => import("./../../../../src/6. Data structure.mdx" /* webpackChunkName: "component---src-6-data-structure-mdx" */),
  "component---src-7-faq-mdx": () => import("./../../../../src/7. FAQ.mdx" /* webpackChunkName: "component---src-7-faq-mdx" */),
  "component---src-index-mdx": () => import("./../../../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

